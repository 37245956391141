import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import App from "./containers/App";
import red from "@material-ui/core/colors/red";
import { createMuiTheme } from "@material-ui/core/styles";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  appVersion: process.env.REACT_APP_VERSION
});

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#A5333C"
    },
    secondary: {
      main: "#19857b"
    },
    error: {
      main: red.A400
    },
    background: {
      default: "#fff"
    }
  }
});

ReactDOM.render(
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
