import React, {Component} from 'react';
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from '@material-ui/core/Slide';

import { isIos, isAndroid, isInStandaloneMode, getBasicAuth } from "../../utils";

const styles = theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  title: {
    flexGrow: 1
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  errorSnackbar: {
    backgroundColor: "#B71C1C"
  },
  wrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "55%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  infoBadge: {
    color: "#fff",
    fontWeight: "500",
    backgroundColor: "#4caf50",
    padding: '5px',
  }
});

class Login extends Component {

  state = {
    formData: {
      accessCode: ""
    },
    showError: false,
    isValid: false,
    loading: false,
    bannerVisible: true,
    helperVisible: false,
    Transition: null,
  };

  constructor(props) {
    super(props);
    this.apiUrl = process.env.REACT_APP_WALK_APP_API_URL;
  }

  onAddToHomescreenClick = () => {
    this.setState({ helperVisible: true, Transition: props => <Slide {...props} direction={isIos() ? 'up' : 'down'} />});
  };

  handleCloseHelperClick = () => {
      this.setState({ helperVisible: false });
  }

  handleCloseBannerBtnClick = () => this.setState({ bannerVisible: false });

  formSubmitHandler = async event => {
    event.preventDefault();

    this.setState({ loading: true });
    
    const config = {
      data: JSON.stringify(this.state.formData),
      method: "post",
      url: this.apiUrl + "walk/login"
    };
    const auth = getBasicAuth();
    if (auth) {
      config.headers = {
        Authorization: auth
      };
    }    

    const response = await axios(config);    
    
    if (response.data.status === "ok") {
        localStorage.setItem("authToken", response.data.authToken);
        localStorage.setItem("campaignName", response.data.campaignName);
        this.props.setLoggedIn(true, response.data.campaignName);
        this.setState({ loading: false });
        this.props.history.push("/packet");
    } else if (response.data.status === "error") {
        localStorage.removeItem("authToken");
        this.props.setLoggedIn(false);
        this.setState({ showError: true, loading: false });
    }
  };

  inputChangeHandler = event => {
    const isValid = event.target.value.split(" ").length === 3;
    this.setState({
      formData: {
        accessCode: event.target.value
      },
      isValid: isValid
    });
  };

  componentDidMount() {
    if (localStorage.getItem("authToken") !== null) {
      this.props.setLoggedIn(true);
      this.props.history.push("/packet");
    }
  }

  handleSnackBarClose() {
    this.setState({ showError: false });
  }

  render() {
    const { classes } = this.props;

    const { bannerVisible } = this.state;
    const shouldRender = bannerVisible && !isInStandaloneMode() && (isAndroid() || isIos());

    if (shouldRender) {
      return (
        <Container maxWidth="sm">
          <Grid container spacing={1}>
            <Snackbar
              open={this.state.helperVisible}
              anchorOrigin={{ vertical: isIos() ? 'bottom' : 'top', horizontal: 'center' }}
              TransitionComponent={this.state.Transition}
              onClose={this.handleCloseHelperClick.bind(this)}
            >
              {isIos() ? (
                <Paper elevation={6} className={classes.infoBadge}>
                  To install the app, click the&nbsp;
                  <img
                    src="/assets/buttons/ios_share.png"
                    alt="share"
                    width="16"
                  ></img>
                  &nbsp;Sharing option in the bottom toolbar and select "Add to Home Screen."
                </Paper>
              ) : (
                <Paper elevation={6} className={classes.infoBadge}>
                  To install the app, click the&nbsp;
                  <img
                    src="/assets/buttons/3-dots.png"
                    alt="3-dot"
                    width="16"
                  ></img>
                  &nbsp;Setting icon and select "Add to Home Screen."
                </Paper>
              )}
            </Snackbar>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.title}>
                For the best experience, install the app on your device.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.onAddToHomescreenClick.bind(this)}
              >
                Install App
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.title} align={"center"}>
                - or -
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={this.handleCloseBannerBtnClick.bind(this)}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Container>
      );
    }

    const snackBar = (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={this.state.showError}
        autoHideDuration={10000}
        onClose={this.handleSnackBarClose.bind(this)}
        ContentProps={{
          classes: {
            root: classes.errorSnackbar
          },
          "aria-describedby": "message-id"
        }}
        message={
          <span id="message-id">
            Could not find access code. Please try again.
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={this.handleSnackBarClose.bind(this)}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    );

    return (
      <Container maxWidth="sm">
        <div className={classes.paper}>
          <form
            className={classes.form}
            onSubmit={this.formSubmitHandler}
            noValidate
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="campaign-access-code"
              label="Campaign Access Code"
              name="campaign_access_code"
              autoComplete="email"
              autoFocus
              onChange={this.inputChangeHandler}
            />
            <div className={classes.wrapper}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={this.state.loading || !this.state.isValid}
              >
                Next
              </Button>
              {this.state.loading && (
                <CircularProgress
                  size={22}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </form>
          {snackBar}
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(Login);