import React, { Component } from "react";
import { matchPath, Route } from "react-router";
import db from "../../db";
import * as updater from "../../updater";
import { isCachedMapExists, getBasicAuth } from "../../utils";
import StreetListing from "./StreetListing/StreetListing";

import PacketForm from "./PacketForm/PacketForm";
import NavigationDrawer from "./NavigationDrawer/NavigationDrawer";

class Packet extends Component {
  state = {
    formData: {
      packetId: "",
      showWalkedAddresses: true,
      showUnwalkedAddresses: true,
      showPhoneNumbers: false,
      showAllVoters: true
    },
    hasPacket: false,
    selectedStreet: null,
    packet: {},
    addresses: {},
    groups: {},
    packetCode: null,
    showMap: false,
    showPacketError: false,
    showPacketLoading: false,
    syncCount: 0,
    isSyncing: false,
    dbRefreshCount: 0,
    streetNames: []
  };

  constructor(props) {
    super(props);
    this.apiUrl = process.env.REACT_APP_WALK_APP_API_URL;

    updater.setFetchPacketDataConfigCallback(() => {
      const config = {
        params: this.state.formData,
        headers: {
          'Authorization-Token': "Bearer " + localStorage.getItem("authToken")
        },
        method: "get",
        url: this.apiUrl + "walk/packet"
      };
      const auth = getBasicAuth();
      if (auth) {
        config.headers.Authorization = auth;        
      }  
      return config;
    });
  }

  handleSwitchToggle = key => {
    const obj = { formData: { ...this.state.formData } };
    obj.formData[key] = !this.state.formData[key];
    this.setState(obj);
  };

  toggleWalkedAddresses = event => {
    event.stopPropagation();
    const obj = { formData: { ...this.state.formData } };
    obj.formData.showWalkedAddresses = !this.state.formData.showWalkedAddresses;
    this.setState(obj);
  };

  formSubmitHandler = event => {
    event.preventDefault();
    this.fetchPacketData();
  };

  signOut = () => {
    localStorage.removeItem("authToken");
    this.props.setLoggedIn(false);
    this.props.history.push("/");
  };

  changePacket = () => {
    this.props.history.push("/packet");
  };

  filterByStreet = () => {
    return new Promise((resolve, reject) => {
      db.table("addresses")
        .orderBy("streetName")
        .uniqueKeys(streetNames => {
          resolve(streetNames);
        });
    });
  };

  createStreetListing = async () => {
    const allStreetNames = [];
    const streetNames = await this.filterByStreet();
    const findByStreet = street => {
      return new Promise((resolve, reject) => {
        let completeTotal = 0;
        let total = 0;
        let hideTotal = 0;
        db.table("addresses")
          .where({ streetName: street })
          .each(address => {
            if (address.status > 2) {
              completeTotal++;
            }
            if (address.hide) {
              hideTotal++;
            }
            total++;
          })
          .then(() => {
            allStreetNames.push({
              name: street,
              complete: completeTotal,
              total: total,
              hidden: hideTotal
            });
            resolve();
          });
      });
    };
    for (let name of streetNames) {
      await findByStreet(name);
    }
    this.setState({ streetNames: allStreetNames });
  };

  fetchPacketData = () => {    
    updater.fetch();
  }

  clearDb() {
    return Promise.all([
      db.table("lastPacketCode").clear(),
      db.table("groups").clear(),
      db.table("addresses").clear(),
      db.table("packet").clear(),
      db.table("geoCodes").clear()
    ]);
  }

  toDataURL = (src, packetId) => {
    const url = "https:" + src;

    return new Promise(async (resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";

      img.addEventListener("load", () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        const imageData = canvas.toDataURL("image/png");
        localStorage.setItem("cacheMap", JSON.stringify(imageData));
        localStorage.setItem("cacheMapId", packetId);
        resolve(JSON.stringify(imageData));
      });

      img.addEventListener("error", ev => {
        console.log(ev); //Exepection error....
        reject(ev);
      });

      img.src = url + "?" + new Date().getTime();
    });
  };

  saveToDb(packetData) {
    packetData.packet.packetString = packetData.packetCode;
    packetData.addresses.map(address => {
      address.packetCode = packetData.packet.packetCode;
      address.hide = address.status === 3;
      return address;
    });
    packetData.groups.map(group => {
      group.packetCode = packetData.packet.packetCode;
      return group;
    });
    Promise.all([
      db.table("packet").put(packetData.packet),
      db.table("addresses").bulkPut(packetData.addresses),
      db.table("groups").bulkPut(packetData.groups),
      db.table("geoCodes").bulkPut(Object.values(packetData.markers)),
      db.table("lastPacketCode").put({
        id: 1,
        packetCode: packetData.packet.packetCode,
        packetString: packetData.packetCode,
        date: new Date().toISOString().slice(0, 10)
      })
    ]).then(async () => {
      window.loadingText = "Retrieving overview map";
      if (!isCachedMapExists(packetData.packet)) {
        await this.toDataURL(
          packetData.packet.urlMap,
          this.state.formData.packetId
        );
      }
      this.setState({
        packetCode: packetData.packet.packetCode,
        packet: packetData.packet,
        hasPacket: true
      });
      if (
        !!matchPath(this.props.location.pathname, {
          path: "/packet",
          exact: true
        })
      ) {
        this.props.history.push("/packet/streets");
      }
    });
  }

  selectStreet = async street => {
    if (street === null) {
      await this.createStreetListing();
      this.setState({ selectedStreet: null });
    } else {
      this.setState({ selectedStreet: street.name.trim().toUpperCase() });
    }
  };

  componentDidMount() {
    window.loadingText = "";
    this.handleConnectionChange();
    window.addEventListener("online", this.handleConnectionChange.bind(this));
    window.addEventListener("offline", this.handleConnectionChange.bind(this));

    const currDate = new Date().toISOString().slice(0, 10);
    db.table("lastPacketCode")
      .where({ id: 1, date: currDate })
      .first()
      .then(lastPacket => {
        if (lastPacket !== undefined) {
          window.loadingText = "Generating database from cache";
          db.table("packet")
            .where({ packetCode: parseInt(lastPacket.packetCode) })
            .first()
            .then(async packet => {
              const packetCodeString = this.formatPacketNumber(
                lastPacket.packetCode.toString()
              );
              await this.createStreetListing();
              this.setState({
                formData: {
                  ...this.state.formData,
                  packetId: packetCodeString
                },
                packet: packet,
                packetCode: packet.packetCode,
                packetString: packetCodeString,
                hasPacket: true
              });
              //this.props.history.push('/packet/streets');
            });
        } else if (this.state.formData.packetId) {
          this.fetchPacketData();
        } else {
          this.props.history.push("/packet");
        }
      });

    this.subsId = updater.subscribeEvent(event => {
      switch(event.type) {
        case updater.EV_TASKS_COUNT:
          this.setState({
            syncCount: event.data.count
          });
          break;
        case updater.EV_SYNCING:
          this.setState({
            isSyncing: event.data.syncing
          })
          break;
        case updater.EV_FETCH_START:
          this.setState({ showPacketLoading: true });
          window.loadingText = "Downloading packet list";
          break;
        case updater.EV_FETCH_COMPLETED:
          if (!event.data.error) {
            if (event.data.status === "ok") {
              this.clearDb().then(async () => {
                await this.saveToDb(event.data);
                await this.createStreetListing();
    
                window.loadingText = "";
                this.setState(prevState => ({ showPacketLoading: false, dbRefreshCount: prevState.dbRefreshCount + 1 }));
              });
            } else if (event.data.status === "error") {
              console.log("Server returned error.");
              this.setState({
                showPacketError: true,
                showPacketLoading: false,
                hasPacket: false,
                packet: {},
                addresses: {},
                groups: {},
                packetCode: null
              });
            }
          } else {
            console.log(
              "Cannot communicate with server. Downloading packet is failed.",
              event.data.error
            );
            this.setState({
              showPacketLoading: false
            });
          }
          break;   
        case updater.EV_STATUS_UPDATE:
          this.recalculateCompletedPacket();
          break; 
        default:
      }      
    });
  }

  recalculateCompletedPacket = () => {    
    let countComplete = 0;    
    db.table('addresses').each((address) => {
      if (address.status > 2) {
        ++countComplete;
      }      
    }).then(() => {            
      const packet = { ...this.state.packet, countComplete };      
      this.setState({ packet });
      db.table('packet').put(packet);
    });    
  }

  componentWillUnmount() {
    window.removeEventListener(
      "online",
      this.handleConnectionChange.bind(this)
    );
    window.removeEventListener(
      "offline",
      this.handleConnectionChange.bind(this)
    );

    if (this.subsId) {
      updater.unsubscribeEvent(this.subsId);
    }
  }

  handleConnectionChange() {
    const condition = navigator.onLine ? "online" : "offline";
    const { props } = this;

    if (condition === "online") {
      console.log("Internet connection has returned. Online mode now.");
      props.setDisconnected(false);
      updater.sync();      
    } else {
      console.log("Internet connection has lost. Offline mode now.");
      props.setDisconnected(true);
    }
  }

  syncAndFetch() {
    updater.sync(true);        
  }  

  inputChangeHandler = event => {
    this.setState({
      formData: {
        ...this.state.formData,
        packetId: this.formatPacketNumber(event.target.value)
      }
    });
  };

  formatPacketNumber(value) {
    const packetNumberChunks = [];
    const input = value
      .trim()
      .split("-")
      .join("");
    const reversedInput = input
      .split("")
      .reverse()
      .join("");

    for (
      let i = 0, charsLength = reversedInput.length;
      i < charsLength;
      i += 3
    ) {
      packetNumberChunks.push(reversedInput.substring(i, i + 3));
    }

    return packetNumberChunks
      .join("-")
      .split("")
      .reverse()
      .join("");
  }

  handlePacketErrorClose() {
    this.setState({ showPacketError: false });
  }

  updateSyncCount() {
    return db
      .table("syncQueue")
      .count()
      .then(count => {
        this.setState({
          syncCount: count
        });
      });
  }

  render() {
    let drawerAdditionalOptions =
      !!matchPath(this.props.location.pathname, "/packet/addresses") ||
      !!matchPath(this.props.location.pathname, "/packet/streets") ||
      !!matchPath(this.props.location.pathname, "/packet/map");
    return (
      <>
        <NavigationDrawer
          drawerAdditionalOptions={drawerAdditionalOptions}
          showDrawer={this.props.showDrawer}
          toggleDrawer={this.props.toggleDrawer}
          changePacket={this.changePacket}
          signOut={this.signOut}
          toggleWalkedAddresses={this.toggleWalkedAddresses}
          showWalkedAddresses={this.state.formData.showWalkedAddresses}
          toggleUnwalkedAddresses={this.toggleUnwalkedAddresses}
          showUnwalkedAddresses={this.state.formData.showUnwalkedAddresses}
          handleSwitchToggle={this.handleSwitchToggle}
          showPhoneNumbers={this.state.formData.showPhoneNumbers}
          showAllVoters={this.state.formData.showAllVoters}
          updateAvailable={this.props.updateAvailable}
          refreshApp={this.props.refreshApp}
        />
        <Route
          path={this.props.match.path}
          exact
          render={props => {
            return (
              <PacketForm
                {...props}
                formSubmitHandler={this.formSubmitHandler}
                inputChangeHandler={this.inputChangeHandler}
                loading={this.state.showPacketLoading}
                showPacketError={this.state.showPacketError}
                packetValue={this.state.formData.packetId}
                handlePacketErrorClose={this.handlePacketErrorClose.bind(this)}
              />
            );
          }}
        />
        <Route
          path={this.props.match.path + "/streets"}
          exact
          render={props => {
            return (
              <StreetListing
                {...props}
                packet={this.state.packet}
                streetNames={this.state.streetNames}
                createStreetListing={this.createStreetListing.bind(this)}
                formData={this.state.formData}
                selectStreet={this.selectStreet}
                packetCode={this.state.packetCode}
                // setShowBackButton={this.props.setShowBackButton}
                showWalkedAddresses={this.state.formData.showWalkedAddresses}
                showUnwalkedAddresses={
                  this.state.formData.showUnwalkedAddresses
                }
                showAllVoters={this.state.formData.showAllVoters}
                showPhoneNumbers={this.state.formData.showPhoneNumbers}
                isDisconnected={this.props.isDisconnected}
                setDisconnected={this.props.setDisconnected}
                toggleDrawer={this.props.toggleDrawer}
                updateAvailable={this.props.updateAvailable}
                updateSyncCount={this.updateSyncCount.bind(this)}
                syncCount={this.state.syncCount}
                syncAndFetch={this.syncAndFetch.bind(this)}
                isSyncing={this.state.isSyncing}
                isFetching={this.state.showPacketLoading}
                selectedStreet={this.state.selectedStreet}
                dbRefreshCount={this.state.dbRefreshCount}
              />
            );
          }}
        />
      </>
    );
  }
}

export default Packet;
